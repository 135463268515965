import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  PaddedSectionThreeElement,
  PaddedSectionOneElement,
  PaddedSectionTwoElement,
  Element,
  ScreenPaddedContainer,
} from '../../components';
import './work-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  WideCardElement,
  SmallImageCardElement,
  CtaSection,
  FullWidthPageLayout,
  FloatingImageElement
} from '../../fragments';
import { PaddedHeadlineSection } from '../../fragments/sections';

type Props = {
}

const WorkView: React.FC<Props> = (props) => {

  const data = loaders.loadWorkData();

  return (
    <FullWidthPageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PaddedHeadlineSection data={{
        title: 'What we do at 2Front'
      }} />
      <PaddedSectionTwoElement className='spaced-top' alignment='left'>
        <FloatingImageElement small data={{
          top: '/images/2front-process-1.png',
          alt: ''
        }} />
        <Element className='flex'>
          <h2>
            PROCESS
          </h2>
        </Element>
      </PaddedSectionTwoElement>
      <ScreenPaddedContainer>
        <hr />
      </ScreenPaddedContainer>
      <PaddedSectionThreeElement>
        <WideCardElement data={{ title: 'Ideation', text: 'We discover what you need!' }} />
        <WideCardElement data={{ title: 'Iteration', text: 'We improve until we reach perfection!' }} />
        <WideCardElement data={{ title: 'Realization', text: 'We deliver and maintain results!' }} />
      </PaddedSectionThreeElement>
      <PaddedSectionTwoElement className='spaced-top' alignment='left'>
        <FloatingImageElement small data={{
          top: '/images/2front-process-2.png',
          alt: ''
        }} />
        <Element className='flex'>
          <h2>
            METHODS
          </h2>
        </Element>
      </PaddedSectionTwoElement>
      <ScreenPaddedContainer>
        <hr />
      </ScreenPaddedContainer>
      <PaddedSectionThreeElement>
        <WideCardElement data={{ title: 'Dedicated', text: 'to your needs' }} />
        <WideCardElement data={{ title: 'Briefings', text: 'performed weekly' }} />
        <WideCardElement data={{ title: 'Experts', text: 'in-house and affiliate' }} />
      </PaddedSectionThreeElement>
      <PaddedSectionTwoElement className='spaced-top' alignment='left'>
        <FloatingImageElement small data={{
          top: '/images/2front-process-3.png',
          alt: ''
        }} />
        <Element className='flex'>
          <h2>
            SUPPORT
          </h2>
        </Element>
      </PaddedSectionTwoElement>
      <ScreenPaddedContainer>
        <hr />
      </ScreenPaddedContainer>
      <PaddedSectionThreeElement>
        <WideCardElement data={{ title: '24/7', text: 'support' }} />
        <WideCardElement data={{ title: 'Guarantee', text: 'of satisfaction' }} />
        <WideCardElement data={{ title: 'Long-term', text: 'partnership and maintenance' }} />
      </PaddedSectionThreeElement>
      <PaddedSectionOneElement className='spaced-top'>
        <WideCardElement data={{ title: 'So what do we actually do?' }}>
          <p>Discover our specialty:</p>
        </WideCardElement>
      </PaddedSectionOneElement>
      <PaddedSectionThreeElement>
        <SmallImageCardElement data={{
          image: {
            top: '/images/2front-brands.png',
            alt: 'Design work'
          },
          title: 'DESIGN',
          text: 'Grow your projects through strategic design!',
          link: model.paths.design
        }} />
        <SmallImageCardElement data={{
          image: {
            top: '/images/2front-apps.png',
            alt: 'Development work'
          },
          title: 'DEVELOPMENT',
          text: 'Penetrate the market with custom web and mobile solutions!',
          link: model.paths.development,
        }} />
        <SmallImageCardElement data={{
          image: {
            top: '/images/2front-marketing.png',
            alt: 'Marketing work'
          },
          title: 'GROWTH',
          text: 'Drive new customers and keep current ones!',
          link: model.paths.marketing
        }} />
      </PaddedSectionThreeElement>
      <CtaSection
        data={{
          title: 'What can we do for you?',
          primary: {
            text: 'Book a FREE strategy session',
            link: model.paths.getStarted
          }
        }}
      />
    </FullWidthPageLayout>
  );
}

WorkView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(WorkView);
