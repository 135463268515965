import React from 'react';
import { connect } from 'react-redux';
import {
  placeholderAction,
  getPlaceholder
} from '../../redux/placeholder';
import { RootState } from '../../redux';
import { PlaceholderComponent } from '../../components';
import './placeholder-view.scss';
import { loaders } from '../../services';
import { PageLayout } from '../../fragments';

type Props = {
  placeholder: undefined
}

const PlaceholderView: React.FC<Props> = (props) => {

  const data = loaders.loadHomeData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PlaceholderComponent />
    </PageLayout>
  );
}

PlaceholderView.defaultProps = {
  placeholder: undefined
}

const mapState = (state: RootState) => {
  return ({
    placeholder: getPlaceholder(state),
  })
}

const mapDispatch = {
  placeholder: placeholderAction
}

export default connect(mapState, mapDispatch)(PlaceholderView);
