import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  Button
} from '../../components';
import './careers-view.scss';
import { model } from '../../model';
import {
  CultureFitSection,
  SpiritSection,
  OfficeLifeSection,
  JobListSection,
  CtaSection,
  PageLayout,
} from '../../fragments';
import { loaders } from '../../services';
import { PaddedHeadlineSection } from '../../fragments/sections';

type Props = {
}

const CareersView: React.FC<Props> = (props) => {

  const data = loaders.loadCareersData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PaddedHeadlineSection data={{
        title: 'Careers at 2Front'
      }}>
        <Button
          to={`${model.paths.careers}/#job-list`}
          secondary
        >
          Checkout available positions
        </Button>
      </PaddedHeadlineSection>
      <CultureFitSection />
      <OfficeLifeSection />
      <SpiritSection />
      <JobListSection
        id='job-list'
        data={{
          image: {
            top: model.assets.images.floating.careerPositions1,
            middle: model.assets.images.floating.careerPositions2,
            bottom: model.assets.images.floating.careerPositions3,
            alt: 'Position icons'
          },
          title: 'Currently open positions:',
          jobs: [
            model.consts.careers.wordpressDeveloper,
          ]
        }}
      />
      <CtaSection data={{
        title: 'Maybe you are an expert in something else?',
        primary: {
          text: 'Tell us your expertise',
          link: model.paths.openJob
        }
      }} />
    </PageLayout>
  );
}

CareersView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(CareersView);
