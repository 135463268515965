import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  PaddedSectionTwoElement,
} from '../../components';
import './about-view.scss';
import {
  model
} from '../../model';
import { loaders } from '../../services';
import {
  CtaSection,
  FloatingImageElement,
  WideCardElement,
  FullWidthPageLayout
} from '../../fragments';
import { PaddedHeadlineSection } from '../../fragments/sections';

type Props = {
}

const AboutView: React.FC<Props> = (props) => {

  const data = loaders.loadAboutData();

  return (
    <FullWidthPageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PaddedHeadlineSection data={{
        title: 'About us'
      }} />
      <PaddedSectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: '/images/2front-about-people.png',
          alt: 'About 2Front people'
        }}
          hoverable />
        <WideCardElement
          className='text-card-element'
          data={{
            title: 'People of 2Front',
            cta: 'Meet the crew',
            link: model.paths.people,
            alignment: 'right'
          }}>
          We are young professionals creating great brands, apps and marketing campaigns.
        </WideCardElement>
      </PaddedSectionTwoElement>
      <PaddedSectionTwoElement alignment='right'>
        <WideCardElement
          className='text-card-element bigger-bottom'
          data={{
            title: 'About 2Front',
            cta: 'Discover the Front',
            link: model.paths.company,
            alignment: 'left'
          }}>
          Every day, we work on building great brands, apps and marketing campaigns. This is our story.
        </WideCardElement>
        <FloatingImageElement data={{
          top: '/images/2front-about-company.png',
          alt: 'About 2Front company'
        }} />
      </PaddedSectionTwoElement>
      <CtaSection data={{
        title: 'What do we do?',
        primary: {
          text: 'Find out',
          link: model.paths.work
        }
      }} />
    </FullWidthPageLayout>
  );
}

AboutView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(AboutView);
