import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionOneElement,
  Element,
  Button
} from '../../components';
import './privacy-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  CtaSection,
  HeadlineSection,
  PageLayout
} from '../../fragments';

type Props = {
}

const PrivacyView: React.FC<Props> = (props) => {

  const data = loaders.loadPrivacyData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'Privacy policy'
      }} />
      <SectionOneElement >
        <Element className='privacy-text-container'>
          <h3><i>Last updated: 20 January 2020</i></h3>
          <h2>
            General information
          </h2>
          <h3>
            1.0 What we do?
          </h3>
          <p>
            We help businesses develop and communicate their brands through software and marketing solutions. We are a small digital agency with a fresh mindset and a strong work ethic and as such provide our clients a high level of personalization in our solutions and services.
          </p>
          <h3>
            1.1 Data we collect
          </h3>
          <p>
            In order to make our contents more accessible to our users, we have to collect, analyze and use some information our users provide.
          </p>
          <p>
            We do not sell, rent, or lease your data to third parties, nor intend to in the future, and we take great care of keeping your data confidential at all times.
          </p>
          <h4>
            1.1.1 Some cases of us collecting your personal information:
          </h4>
          <h5>
            a) Intent to collaborate on our projects and use our services
          </h5>
          <p>
            Your personal information such as name, e-mail address and telephone number as well as other info you submit in the description of your project will be stored and used only for business development purposes and only for the period of our negotiation process.
          </p>
          <h5>
            b) Intent to become an employee
          </h5>
          <p>
            When you submit your personal data through a job inquiry at 2Front, they're going to be used solely for the purpose of better career selection. We keep your data only for the duration of the job contest.
          </p>
          <h5>
            c) General inquiry via <Button href='mailto:info@2front.co' bold>info@2front.co</Button> or <Button href="mailto:get@2front.co" bold>get@2front.co</Button>
          </h5>
          <p>
            When contacting us regarding business opportunities, employment, sponsorship or anything else via these email addresses, we will first evaluate the nature of the inquiry and proceed accordingly respecting your personal data and processing the minimum of it required to handle the inquiry.
          </p>
          <h5>
            d) Analytics
          </h5>
          <p>
            We may use aggregated information that’s collected to understand general information and trends related to our website, such as how many users have visited our web site during a given period of time, and the types of devices the visitors use. The information can’t be used to identify an individual and is used by us to help improve the solution for consumers.
          </p>
          <p>
            For such analyses we use a third party service, Google Analytics. Here you can find their <Button href='https://policies.google.com/privacy?hl=en' bold>Privacy Policy</Button>.
          </p>
          <h5>
            e) Cookies
          </h5>
          <p>
            In order to make our site as user-friendly as possible, we use cookies. More on this in our <Button to={model.paths.cookies} bold>Cookie Policy</Button>.
          </p>
          <h5>
            f) Social media contacts
          </h5>
          <p>
            When contacting us with the inquiries of any kind through social media, we will first evaluate the nature of the inquiry and proceed accordingly respecting your personal data and processing the minimum of it required to handle the inquiry.
          </p>
          <p>
            2Front has an official profile on three social networks:
          </p>
          <p>Facebook: <Button bold href='https://www.facebook.com/2front.co/'>https://www.facebook.com/2front.co/</Button></p>
          <p>Instagram: <Button bold href='https://www.instagram.com/2front.co/'>https://www.instagram.com/2front.co/</Button></p>
          <p>Linkedin: <Button bold href='https://www.linkedin.com/company/2front/'>https://www.linkedin.com/company/2front/</Button></p>
          <h3>
            1.2 Where do we store your data
          </h3>
          <p>
            The data you provide via forms on our website is stored in our databases and can be erased at your request. The data gathered through Google Analytics is stored on Google’s servers and will be removed periodically as stated in this document.
          </p>
          <h3>
            1.3 Who we share your data with
          </h3>
          <p>
            We do not share our user’s data with anybody. The protection and safety of our client’s data is of our utmost concern. Only data being viewed through third party apps is Google Analytics data which comprises only of browsing activity on our website.
          </p>
          <h3>
            1.4 How long do we keep your information
          </h3>
          <p>
            We do not keep your data longer than we need to. After a maximum of 60 days from collection personal information from our users will be erased, assuming the information is not submitted again and unless specified differently in the sections above.
          </p>
          <h2>
            2.0 Your data protection rights
          </h2>
          <p>
            We take your privacy very seriously, so we would like to make sure you are fully aware of all of your data protection rights.
          </p>
          <h3>
            2.1 Rights your are entitled to:
          </h3>
          <h5>
            a) The right to access
          </h5>
          <p>
            You have the right to request 2Front for copies of your personal data. We may charge you a small fee for this service.
          </p>
          <h5>
            b) The right to rectification
          </h5>
          <p>
            You have the right to request that we correct any information you believe is inaccurate. You also have the right to request 2Front to complete the information you believe is incomplete.
          </p>
          <h5>
            c) The right to erasure
          </h5>
          <p>
            You have the right to request that we erase your personal data, under certain conditions.
          </p>
          <h5>
            d) The right to restrict processing
          </h5>
          <p>
            You have the right to request that 2Front restrict the processing of your personal data, under certain conditions.
          </p>
          <h5>
            e) The right to object to processing
          </h5>
          <p>
            You have the right to object to our processing of your personal data, under certain conditions.
          </p>
          <h5>
            f) The right to data portability
          </h5>
          <p>
            You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          </p>
          <p>
            If you encounter any irregularities regarding this, please contact us at <Button href='mailto:support@2front.co' bold>support@2front.co</Button>, and we will make sure to get back to you shortly.
          </p>
          <h2>
            3.0 Data protection authority
          </h2>
          <p>
            Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact Croatian Personal Data Protection Agency at <Button href='mailto:azop@azop.hr' bold>azop@azop.hr</Button>.
          </p>
        </Element>
      </SectionOneElement>
      <CtaSection data={{
        title: 'Read next:',
        primary: {
          link: model.paths.work,
          text: 'What we do'
        }
      }}
      />
    </PageLayout>
  );
}

PrivacyView.defaultProps = {
}

const mapState = (state: RootState) => {

}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(PrivacyView);
