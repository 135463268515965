import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionOneElement,
  Element,
  Button,
  splashScreenHOC,
  onScrollHOC
} from '../../components';
import './careers.scss';
import { model } from '../../model';
import {
  JobQualificationsSection,
  SpiritSection,
  OfficeLifeSection,
  JobTechSection,
  JobFormElement,
  CtaSection,
  PageLayout,
  HeadlineSection
} from '../../fragments';
import { loaders } from '../../services';

type Props = {
}

const WordpressDeveloperView: React.FC<Props> = (props) => {

  const data = loaders.loadCareerData();
  const job = model.consts.careers.wordpressDeveloper;

  return (
    <PageLayout
      currentPath={data.wordpressDeveloper.path}
      data={data.wordpressDeveloper.global}
      title={data.wordpressDeveloper.pathname}
    >
      <HeadlineSection data={{
        title: 'Wordpress Developer'
      }}>
        <Button
          to={`${model.paths.wordpressDeveloper}#application-form`}
          primary
        >
          Apply for the Front
        </Button>
      </HeadlineSection>
      <OfficeLifeSection />
      <SpiritSection />
      <JobQualificationsSection data={{
        title: 'Is this You?',
        qualifications: job.qualifications,
        responsibilities: job.responsibilities,
        extras: job.extras,
      }} />
      <JobTechSection data={{
        title: 'Technologies you will use:',
        tech: job.tech
      }} />
      <SectionOneElement>
        <Element>
          <h2>
            Apply for the Front
          </h2>
        </Element>
        <JobFormElement
          pageTitle={model.pathnames[model.paths.wordpressDeveloper]}
        />
      </SectionOneElement>
      <CtaSection data={{
        title: 'What can we do together?',
        primary: {
          text: 'Show me',
          link: model.paths.work
        }
      }} />
    </PageLayout>
  );
}

WordpressDeveloperView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(WordpressDeveloperView);
