import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../redux';
import './guerilla-view.scss';
import { loaders } from '../../../services';
import {
  WideCardElement,
  PageLayout
} from '../../../fragments';
import {
  PaddedSectionOneElement,
  Element,
  Button,
  PaddedSectionTwoElement
} from '../../../components';
import {
  CtaSection,
} from '../../../fragments/sections';
import { model } from '../../../model';

type Props = {
}

const GuerillaView: React.FC<Props> = (props) => {

  const data = loaders.loadGuerillaData();

  let [companyName, setCompanyName] = useState('2Front Digitalna Agencija');
  let [companyDescription, setCompanyDescription] = useState('Brands, Apps and Marketing Campaigns.');
  let [companyPhone, setCompanyPhone] = useState('Broj nije dostupan');
  let [companyAddress, setCompanyAddress] = useState('Adresa nije dostupna');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setCompanyName(urlParams.get('name') || '2Front Digitalna Agencija');
    setCompanyDescription(urlParams.get('desc') || 'Brands, Apps and Marketing Campaigns.');
    setCompanyPhone(urlParams.get('phone') || 'Broj nije dostupan');
    setCompanyAddress(urlParams.get('addr') || 'Adresa nije dostupna');
  }, []);

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PaddedSectionTwoElement
        alignment='right'
        //@ts-ignore
        style={{
          marginBottom: '10em',
          marginTop: '7em'
        }}>
        <Element>
          <h1>
            {companyName}
          </h1>
        </Element>
      </PaddedSectionTwoElement>
      <PaddedSectionOneElement>
        <Element>
          <h2>
            {companyDescription}
          </h2>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionTwoElement
        //@ts-ignore
        style={{
          marginBottom: '10em',
          marginTop: '7em'
        }}>
        <Element>
          <h2 >
            Telefon: <span className='accented'>{companyPhone}</span>
          </h2>
        </Element>
        <Element>
          <h2 >
            Adresa: <span className='accented'>{companyAddress}</span>
          </h2>
        </Element>
      </PaddedSectionTwoElement>
      <PaddedSectionOneElement
        //@ts-ignore
        style={{
          marginBottom: '10em',
          marginTop: '7em'
        }}>
        <Element>
          <h2 style={{ fontWeight: 'normal' }}>
            Svaki dan firme gube mnogo novca. Većina ih toga nije svjesna.
          </h2>
          <br /><br />
          <p>
            <b>Loš marketing vodi lošoj prodaji.</b> U današnjem svijetu, online marketing je važniji od marketinga uživo.
            Web stranica je vaš najbolji alat za marketing.
            Brinite se o vašoj stranici. Brinite se o vašoj online prisutnosti. Brinite se o vašem linkovima.
            <br /><br />
            Čini se da je vlasnik zaboravio staviti link na web stranicu svoje firme na Google Maps, pa smo bili malo nestašni i odlučili uskočiti. 
            Unatoč tome, čine se kao dobri ljudi i da znaju puno o tome što rade tako da ih možete slobodno kontaktirati ako ih trebate.
            Hvala Vam za ovaj besplatan marketing prostor. Možete i nas kontaktirati ako želite napraviti ovakvu stranicu po prave.
          </p>
        </Element>
      </PaddedSectionOneElement>
      <CtaSection data={{
        title: 'Želite li prestati gubiti novce na ovakvim i sličnim greškama? Razvijajte svoju firmu s nama!',
        primary: {
          text: 'Dogovorite Vaš BESPLATAN strategy sastanak!',
          link: model.paths.getStarted
        }
      }} />
      <PaddedSectionTwoElement>
        <WideCardElement data={{
          title: 'Zanima Vas kako Vam možemo pomoći?',
          cta: 'Kontaktirajte nas',
          link: model.paths.getStarted,
        }}>
          Naši profesionalci su spremni za Vas!
        </WideCardElement>
        <WideCardElement data={{
          title: "Je li ovo trebala biti Vaša stranica?",
          cta: 'Javite nam se',
          link: model.paths.getStarted
        }}>
          Javite nam se da Vam pomognemo.
        </WideCardElement>
      </PaddedSectionTwoElement>
      <PaddedSectionOneElement className='cta-container'>
        <Element>
          <h2 id='get-started' className='home-cta'>
            <Button to={model.paths.getStarted}>
              Započni odmah<span className='accented'>!</span>
            </Button>
          </h2>
          <span className='cta-subtitle'>
            s besplatnim polusatnim strategy sastankom!
            </span>
        </Element>
      </PaddedSectionOneElement>
    </PageLayout>
  );
}

GuerillaView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(GuerillaView);
