import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  Element,
  Button,
  PaddedSectionOneElement,
  PaddedSectionThreeElement,
  SectionOneElement
} from '../../components';
import './home-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  FullWidthPageLayout,
  SmallImageCardElement,
  PaddedHeadlineSection,
  NewsletterFormElement
} from '../../fragments';

type Props = {
}

const HomeView: React.FC<Props> = (props) => {

  const data = loaders.loadHomeData();

  return (
    <FullWidthPageLayout
      className='home-layout'
      currentPath={data.path}
      data={data.global}
      title={'Brands, Apps and Marketing Campaigns.'}
      {...props}
    >
      <PaddedHeadlineSection data={{
        title: 'Brands, Apps and Marketing Campaigns. Grow your ideas with creative and IT solutions!'
      }
      }>
      </PaddedHeadlineSection>
      <SectionOneElement>
        <Element className='center-title'>
          <Button to={model.paths.getStarted} accent>
            Book a FREE 30 minute strategy session!
        </Button>
        </Element>
      </SectionOneElement>
      <SectionOneElement>
        <Element className='center-title'>
          <Button href='https://websites.2front.co' primary>
            Click here if you need a website!
        </Button>
        </Element>
      </SectionOneElement>
      <PaddedSectionOneElement>
        <Element className='spaced-top'>
          <h2>
            2Front Digital Agency is a prime digital agency in Croatia. We deliver beautiful brands and help our clients grow through creative and development solutions.
          </h2>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionOneElement>
        <Element>
          <p>
            We deliver solutions you need to <b>grow your business</b>. Be it through growth marketing, websites, mobile apps or unique and engaging graphic design, we guarantee you are set for success.
          </p>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionOneElement>
        <Element>
          <h2 className='center-title spaced-top'>
            What is your biggest need?
          </h2>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionThreeElement>
        <SmallImageCardElement data={{
          image: {
            top: '/images/2front-brands.png',
            alt: 'Design work'
          },
          title: 'DESIGN',
          text: 'Grow your projects through strategic design!',
          link: model.paths.design
        }} />
        <SmallImageCardElement data={{
          image: {
            top: '/images/2front-apps.png',
            alt: 'Development work'
          },
          title: 'DEVELOPMENT',
          text: 'Penetrate the market with custom web and mobile solutions!',
          link: model.paths.development,
        }} />
        <SmallImageCardElement data={{
          image: {
            top: '/images/2front-marketing.png',
            alt: 'Marketing work'
          },
          title: 'GROWTH',
          text: 'Drive new customers and keep current ones!',
          link: model.paths.marketing
        }} />
      </PaddedSectionThreeElement>
      <PaddedSectionOneElement className='cta-container'>
        <Element>
          <h2 id='get-started' className='home-cta'>
            <Button to={model.paths.getStarted}>
              Get started<span className='accented'>!</span>
            </Button>
          </h2>
          <span className='cta-subtitle'>
            With a FREE 30 minute strategy session!
            </span>
        </Element>
      </PaddedSectionOneElement>
    </FullWidthPageLayout>
  );
}

HomeView.defaultProps = {

}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(HomeView);
