import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  Element,
  Button,
  PaddedSectionOneElement,
  PaddedSectionThreeElement
} from '../../components';
import './contact-view.scss';
import {
  MapElement,
  FullWidthPageLayout,
  WideCardElement
} from '../../fragments';
import { loaders } from '../../services';
import { model } from '../../model';
import { PaddedHeadlineSection } from '../../fragments/sections';

type Props = {
}

const ContactView: React.FC<Props> = (props) => {

  const data = loaders.loadContactData();

  return (
    <FullWidthPageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PaddedHeadlineSection data={{
        title: 'Contact'
      }} />
      <PaddedSectionOneElement>

      </PaddedSectionOneElement>
      <PaddedSectionThreeElement>
        <WideCardElement data={{
          title: 'Looking for a career?',
          cta: 'Join us!',
          link: model.paths.careers
        }}>
        </WideCardElement>
        <Element>
          <p style={{ textAlign: 'center', fontSize: '1.5em' }}>
            or
          </p>
        </Element>
        <WideCardElement data={{
          title: 'Want to see what we can do for you?',
          cta: 'Book a FREE session!',
          link: model.paths.getStarted
        }}>
        </WideCardElement>
      </PaddedSectionThreeElement>
      <PaddedSectionOneElement>
        <Element className='contact-view-element contact-info-element'>
          <h2>
            Where to find us:
          </h2>
          <p>
            2Front team
            <br />
            Petračićeva 6
            <br />
            10 000 Zagreb
            <br />
            <Button
              href="mailto:get@2front.co"
              bold
            >
              get@2front.co
              </Button>
          </p>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionOneElement>
        <MapElement
          className='contact-view-element'
          data={{
            title: 'Where is that on the map?'
          }}
        />
      </PaddedSectionOneElement>
      <PaddedSectionOneElement>
        <Element>
          <p>
            Poslovna banka: Erste Steiermarkische Bank d. d. Zagreb <br />
            Temeljni kapital društva iznosi 20 000,00 kn i uplaćen u cijelosti kod Trgovačkog suda u Zagrebu. <br />
            Direktor društva: Juraj Pejnović <br />
            OIB: 41237093519 <br />
            IBAN: HR3024020061100967918 <br />
            Kontakt telefon:+385 99 789 5402 <br />
          </p>
        </Element>
      </PaddedSectionOneElement>
    </FullWidthPageLayout>
  );
}

ContactView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({
  })
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(ContactView);
