import React, {
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import './guerilla-view.scss';
import { loaders } from '../../services';
import {
  WideCardElement,
  PageLayout
} from '../../fragments';
import {
  PaddedSectionOneElement,
  Element,
  Button,
  PaddedSectionTwoElement
} from '../../components';
import {
  CtaSection,
} from '../../fragments/sections';
import { model } from '../../model';

type Props = {
}

const GuerillaView: React.FC<Props> = (props) => {

  const data = loaders.loadGuerillaData();
  let [companyName, setCompanyName] = useState('2Front Digital Agency');
  let [companyDescription, setCompanyDescription] = useState('Brands, Apps and Marketing Campaigns.');
  let [companyPhone, setCompanyPhone] = useState('Number not available');
  let [companyAddress, setCompanyAddress] = useState('Address not available');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setCompanyName(urlParams.get('name') || '2Front Digital Agency');
    setCompanyDescription(urlParams.get('desc') || 'Brands, Apps and Marketing Campaigns.');
    setCompanyPhone(urlParams.get('phone') || 'Number not available');
    setCompanyAddress(urlParams.get('addr') || 'Address not available');
  }, []);

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <PaddedSectionOneElement
        //@ts-ignore
        style={{
          marginBottom: '10em',
          marginTop: '7em'
        }}>
        <Element>
          <h1 >
            {companyName}
          </h1>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionOneElement>
        <Element>
          <h2>
            {companyDescription}
          </h2>
        </Element>
      </PaddedSectionOneElement>
      <PaddedSectionTwoElement
        //@ts-ignore
        style={{
          marginBottom: '10em',
          marginTop: '7em'
        }}>
        <Element>
          <h2 >
            Phone: <span className='accented'>{companyPhone}</span>
          </h2>
        </Element>
        <Element>
          <h2 >
            Address: <span className='accented'>{companyAddress}</span>
          </h2>
        </Element>
      </PaddedSectionTwoElement>
      <PaddedSectionOneElement
        //@ts-ignore
        style={{
          marginBottom: '10em',
          marginTop: '7em'
        }}>
        <Element>
          <h3 style={{ fontWeight: 'normal' }}>
            Businesses miss out on a lot of money every single day. Most are not aware of that.
            <br /><br />
            <b>Bad marketing leads to bad sales.</b> A website is your ultimate marketing weapon.
            Take care of your website. Take care of your links. Take care of you google maps.
            <br /><br />
            These guys seem to have forgot to put their business' website on google maps, so we decided to jump in.
            They seem like nice people and know what they are doing, so be sure to contact them if you need them.
            Thank you for this free marketing space. Contact us if you need help setting this up for real.
          </h3>
        </Element>
      </PaddedSectionOneElement>
      <CtaSection data={{
        title: 'Want to stop losing money to mistakes like this? Grow your business with us!',
        primary: {
          text: 'Get a free 30-minute strategy session!',
          link: model.paths.getStarted
        }
      }} />
      <PaddedSectionTwoElement>
        <WideCardElement data={{
          title: 'Interested how we can help your business?',
          cta: 'Get started'
        }}>
          Arrange a strategy session with us!
        </WideCardElement>
        <WideCardElement data={{
          title: "Was this supposed to be your business' website?",
          cta: 'Send a message',
          link: model.paths.getStarted
        }}>
          Contact us so we can give it back to you.
        </WideCardElement>
      </PaddedSectionTwoElement>
      <PaddedSectionOneElement className='cta-container'>
        <Element>
          <h2 id='get-started' className='home-cta'>
            <Button to={model.paths.getStarted}>
              Get started<span className='accented'>!</span>
            </Button>
          </h2>
          <span className='cta-subtitle'>
            With a FREE 30 minute discovery session!
            </span>
        </Element>
      </PaddedSectionOneElement>
    </PageLayout >
  );
}

GuerillaView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(GuerillaView);
