import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionTwoElement
} from '../../components';
import './marketing-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  PageLayout,
  HeadlineSection,
  CtaSection,
  WideCardElement,
  FloatingImageElement
} from '../../fragments';

type Props = {
}

const MarketingView: React.FC<Props> = (props) => {

  const data = loaders.loadMarketingData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'Marketing at 2Front'
      }} />
      <SectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: model.assets.images.floating.sm1,
          middle: model.assets.images.floating.sm2,
          bottom: model.assets.images.floating.sm3,
          alt: 'Logos of Facebook, Instagram and Linkedin.'
        }} />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'social_media',
            title: 'Social Media Marketing',
            alignment: 'left'
          }}>
          Social media are omnipresent, we devise perfect approaches tailored <u>specifically for every platform</u>.
        </WideCardElement>
      </SectionTwoElement>
      <SectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: model.assets.images.floating.strategy1,
          middle: model.assets.images.floating.strategy2,
          bottom: model.assets.images.floating.strategy3,
          alt: 'Flipcharts and drawing boards howing marketing strategy.'
        }} />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'strategy',
            title: 'Marketing Strategy',
            alignment: 'left'
          }}>
          Adjust the target market, asses the competition, <u>calibrate the promotion</u> and fire for success, fueled by data.
        </WideCardElement>
      </SectionTwoElement>
      <SectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: model.assets.images.floating.guerilla1,
          bottom: model.assets.images.floating.guerilla2,
          alt: 'A beret hat with 2Front branding.'
        }} />
        <WideCardElement className='text-card-element'
          data={{
            id: 'guerilla',
            title: 'Guerilla Marketing',
            alignment: 'left'
          }}>
          Some markets cannot be penetrated by conventional means. We use <u>unconventional</u> means to achieve <u>maximum reach</u>.
        </WideCardElement>
      </SectionTwoElement>
      <CtaSection data={{
        title: 'Want to grow with us?',
        accent: {
          text: 'Get started',
          link: model.paths.getStarted,
        },
        secondary: {
          text: 'Or read about our other stuff!',
          link: model.paths.work
        }
      }} />
    </PageLayout>
  );
}

MarketingView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(MarketingView);
