import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionOneElement,
  Element,
  Button,
} from '../../components';
import './cookies-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  HeadlineSection,
  CtaSection,
  PageLayout
} from '../../fragments';

type Props = {
}

const CookiesView: React.FC<Props> = (props) => {

  const data = loaders.loadCookiesData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'Cookie policy'
      }} />
      <SectionOneElement>
        <Element className='cookies-text-container'>
          <h3><i>Last updated: 20 January 2020</i></h3>
          <h2>
            1. 0 What are cookies?
          </h2>
          <p>
            Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our website, we may collect information from you automatically through cookies or similar technology.
          </p>
          <p>
            Cookies generally do not hold any information to identify an individual person but are instead used to identify a browser on an individual machine.
          </p>
          <p>
            If you prefer, you can restrict, block or delete cookies by changing your browser settings but that may mean that the website won't work properly.
          </p>
          <p>
            For more detailed information on cookies and their impact on your browsing experience, visit <Button href='allaboutcookies.org' bold>allaboutcookies.org</Button>.
          </p>
          <h2>
            2. 0 How cookies are used by 2Front
          </h2>
          <h3>
            2.1 Types of cookies
          </h3>
          <h5>
            a) Necessary cookies
          </h5>
          <p>
            Cookies of this type are essential for all of our website’s features to work properly. Without them, some or all of its services will not be available. These cookies do not gather information about you that could be used to identify you, and they do not monitor or remember where you have been on the internet.
          </p>
          <h5>
            b) Functional cookies
          </h5>
          <p>
            2front.co uses these cookies so that we recognize you on our website and remember your previously selected preferences. These cookies allow us to provide you with a better online experience when you use our website and they do not gather or store any information which would allow us to identify you personally.
          </p>
          <h3>
            2.2 How to manage cookies
          </h3>
          <p>
            If you prefer, you can restrict, block or delete cookies by changing your browser settings as explained at the aforementioned <Button href='allaboutcookies.org' bold>website</Button>. However, that may mean that our website won't work properly as as intended.
          </p>
        </Element>
      </SectionOneElement>
      <CtaSection data={{
        title: 'Next up:',
        primary: {
          text: 'What we do',
          link: model.paths.work
        }
      }} />
    </PageLayout>
  );
}

CookiesView.defaultProps = {
}

const mapState = (state: RootState) => {

}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(CookiesView);
