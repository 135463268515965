import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  Element,
  SectionTwoElement,
  splashScreenHOC,
  onScrollHOC,
} from '../../components';
import './company-view.scss';
import {
  model
} from '../../model';
import { loaders } from '../../services';
import {
  HeadlineSection,
  CtaSection,
  PageLayout,
  FloatingImageElement
} from '../../fragments';

type Props = {
}

const CompanyView: React.FC<Props> = (props) => {

  const data = loaders.loadCompanyData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'About 2Front'
      }} />
      <SectionTwoElement alignment='right'>
        <Element className='text-card-element'>
          <div className='about-company-text'>
            <h2>
              Why we do what we do?
            </h2>
            <p>
              Our mission is simple but far from easy: We help businesses and people communicate their brands clearly with their audience.
              We do that through software, marketing and graphic design. Having such varied offer enables us to offer full service customized solutions to our
              clients that encompass the entire lifecycle of their product from start to finish.
            </p>
            <p>
              While our missions seems simple, our vision is much more ambitious:
              A world of clear communication, where every brand is faithfully represented and no company suffers from scandals made by miscommunication.
              We know it's a lost cause, but it still doesn't prevent us from offering our clients the best possible ways of establishing conversation with their audience.
            </p>
            <p>
              In short, our mission and vision are condensed in one single statement:<br /> <b>Absolute communication.</b>
            </p>
          </div>
        </Element>
        <FloatingImageElement data={{
          top: model.assets.images.floating.aboutCompanyMission1,
          middle: model.assets.images.floating.aboutCompanyMission2,
          bottom: model.assets.images.floating.aboutCompanyMission3,
          alt: '2 heads with arrows between them symbolizing communication.'
        }} />
      </SectionTwoElement>
      <SectionTwoElement alignment='right'>
        <Element className='text-card-element'>
          <div className='about-company-text'>
            <h2>
              Why 2Front?
            </h2>
            <p>
              The name 2Front comes from the term used in the military jargon, frontline. The frontlines are always supposed to move forward and such is our spirit.
              To always be at the forefront of technology, methods and skills. But, the name is not 1Front, but 2Front, meaning there are 2 frontlines.
            </p>
            <p>
              The other frontline is pointed backward, inward, constantly revising and improving our methods and digging deeper within our
              skills for things we can share with the world and how to improve our methods.
            </p>
          </div>
        </Element>
        <FloatingImageElement data={{
          top: model.assets.images.floating.aboutCompanyMethods1,
          middle: model.assets.images.floating.aboutCompanyMethods2,
          bottom: model.assets.images.floating.aboutCompanyMethods3,
          alt: 'A drawing of a partially filled to-do list.'
        }} />
      </SectionTwoElement>
      <CtaSection data={{
        title: 'Not enough?',
        primary: {
          text: 'Find out what we do',
          link: model.paths.work
        }
      }} />
      <CtaSection data={{
        title: 'Feel like you know us?',
        primary: {
          text: 'Hire us',
          link: model.paths.contact
        }
      }} />
    </PageLayout>
  );
}

CompanyView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(CompanyView);
