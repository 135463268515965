import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  Element,
  SectionOneElement
} from '../../components';
import './get-started-view.scss';
import {
  ContactFormElement,
  HeadlineSection,
  PageLayout,
} from '../../fragments';
import { loaders } from '../../services';

type Props = {
}

const ContactView: React.FC<Props> = (props) => {

  const data = loaders.loadGetStartedData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'Get started!'
      }} />
      <SectionOneElement>
        <Element className='contact-view-element'>
          <h2>
            Tell us your problem! Claim your FREE discovery session!
          </h2>
          <ContactFormElement />
        </Element>
      </SectionOneElement>
    </PageLayout>
  );
}

ContactView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({
  })
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(ContactView);
