import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionOneElement,
  Element,
  Button
} from '../../components';
import './careers.scss';
import {
  model
} from '../../model';
import {
  JobQualificationsSection,
  SpiritSection,
  OfficeLifeSection,
  JobFormElement,
  JobTechSection,
  PageLayout,
  CtaSection,
  HeadlineSection
} from '../../fragments';
import { loaders } from '../../services';

type Props = {
}

const OpenJobView: React.FC<Props> = (props) => {

  const data = loaders.loadCareerData();
  const job = model.consts.careers.openJob;

  return (
    <PageLayout
      currentPath={data.openJob.path}
      data={data.openJob.global}
      title={data.openJob.pathname}
    >
      <HeadlineSection data={{
        title: 'Define your own career',
        subtitle: 'Not interested in any position listed? Tell us your expertise. We need people like you.'
      }}>
        <Button
          to={`${model.paths.openJob}#application-form`}
          primary
        >
          Apply for the Front
        </Button>
      </HeadlineSection>
      <OfficeLifeSection />
      <SpiritSection />
      <JobQualificationsSection data={{
        title: 'Is this You?',
        qualifications: job.qualifications,
        responsibilities: job.responsibilities,
        extras: job.extras,
      }} />
      <JobTechSection data={{
        title: 'Technologies you will use:',
        tech: job.tech
      }} />
      <SectionOneElement>
        <Element>
          <h2>
            Apply for the Front
          </h2>
        </Element>
        <JobFormElement
          pageTitle={model.pathnames[model.paths.openJob]}
        />
      </SectionOneElement>
      <CtaSection data={{
        title: 'What can we do together?',
        primary: {
          link: model.paths.work,
          text: 'Show me'
        }
      }} />
    </PageLayout>
  );
}

OpenJobView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(OpenJobView);
