import React from 'react';
import { SectionOneElement } from '../../components';
import './error-404-view.scss';
import { model } from '../../model'
import { loaders } from '../../services';
import {
  HeadlineSection,
  CtaSection,
  PageLayout,
  FloatingImageElement
} from '../../fragments';

const Error404View = () => {

  const data = loaders.loadError404Data();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: '404 - This front does not exist ... yet.'
      }} />
      <SectionOneElement>
        <FloatingImageElement data={{
          top: model.assets.images.floating.error4041,
          middle: model.assets.images.floating.error4042,
          bottom: model.assets.images.floating.error4043,
          alt: 'Front not found'
        }} />
      </SectionOneElement>
      <CtaSection data={{
        title: 'What now?',
        primary: {
          link: model.paths.home,
          text: 'Take me home'
        },
        secondary: {
          link: model.paths.contact,
          text: 'Send us a memo'
        }
      }} />
    </PageLayout>
  );
}

export default Error404View;
