import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import { SectionTwoElement } from '../../components';
import './development-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  HeadlineSection,
  CtaSection,
  PageLayout,
  FloatingImageElement,
  WideCardElement
} from '../../fragments';

type Props = {
}

const DevelopmentView: React.FC<Props> = (props) => {

  const data = loaders.loadDevelopmentData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'Development at 2Front'
      }} />
      <SectionTwoElement alignment='left'>
        <FloatingImageElement
          data={{
            top: model.assets.images.floating.mobile1,
            middle: model.assets.images.floating.mobile2,
            bottom: model.assets.images.floating.mobile3,
            alt: 'Apple and Android next to React Native.'
          }}
        />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'mobile_development',
            title: 'Mobile Development',
            alignment: 'left'
          }} >
          Harness the power of <u>React Native</u> and develop your ideas for <u>both Android and iOS</u>.
          </WideCardElement>
      </SectionTwoElement>
      <SectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: model.assets.images.floating.web1,
          middle: model.assets.images.floating.web2,
          bottom: model.assets.images.floating.web3,
          alt: 'Gatsby.js, React.js and Wordpress in a circle.'
        }} />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'web_development',
            title: 'Web Development',
            alignment: 'left'
          }}>
          We stop at nothing to provide the best possible web experience. <u>Cutting edge</u> React.js powers your ideas through classic websites or <u>Progressive Web Apps</u>.
        </WideCardElement>
      </SectionTwoElement>
      <SectionTwoElement alignment='left'>
        <FloatingImageElement
          data={{
            top: model.assets.images.floating.outsource1,
            middle: model.assets.images.floating.outsource2,
            bottom: model.assets.images.floating.outsource3,
            alt: '2Front logomark surrounded by doodles of people'
          }}
        />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'outsource',
            title: 'Outsource your development',
            alignment: 'left'
          }}>
          Add our staff to your team to get a <u>set of fresh eyes</u>. Whether React, Node or C++, our people will <u>help your team</u>.
        </WideCardElement>
      </SectionTwoElement>
      <CtaSection data={{
        title: 'Need our developers?',
        accent: {
          text: 'Get started',
          link: model.paths.getStarted
        },
        secondary: {
          text: 'Or read about our other stuff!',
          link: model.paths.work
        }
      }} />
    </PageLayout>
  );
}

DevelopmentView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(DevelopmentView);
