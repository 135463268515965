import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionThreeElement
} from '../../components';
import './people-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  HeadlineSection,
  CtaSection,
  PageLayout,
  EmployeeElement
} from '../../fragments';

type Props = {
}

const PeopleView: React.FC<Props> = (props) => {

  const data = loaders.loadPeopleData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'People at 2Front'
      }} />
      <SectionThreeElement>
        <EmployeeElement data={{
          image: model.assets.photos.pejnovic,
          name: 'Juraj Pejnović',
          position: 'Founder and CEO'
        }} />
        <EmployeeElement data={{
          image: model.assets.images.floating.aboutPeopleMore,
          name: 'More troops',
          position: 'are being trained'
        }} />
      </SectionThreeElement>
      <CtaSection
        data={{
          title: 'Want to join us?',
          primary: {
            link: model.paths.careers,
            text: 'See available careers'
          }
        }}
      />
    </PageLayout>
  );
}

PeopleView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(PeopleView);
