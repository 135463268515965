import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import {
  SectionTwoElement,
  splashScreenHOC, onScrollHOC
} from '../../components';
import './design-view.scss';
import { model } from '../../model';
import { loaders } from '../../services';
import {
  HeadlineSection,
  CtaSection,
  PageLayout,
  FloatingImageElement,
  WideCardElement
} from '../../fragments';

type Props = {
}

const DesignView: React.FC<Props> = (props) => {

  const data = loaders.loadDesignData();

  return (
    <PageLayout
      currentPath={data.path}
      data={data.global}
      title={data.pathname}
    >
      <HeadlineSection data={{
        title: 'Design at 2Front'
      }} />
      <SectionTwoElement alignment='left'>
        <FloatingImageElement
          data={{
            top: model.assets.images.floating.graphicDesign1,
            middle: model.assets.images.floating.graphicDesign2,
            bottom: model.assets.images.floating.graphicDesign3,
            alt: 'abstract representation of iterative design'
          }} />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'graphic_design',
            title: 'Graphic design',
            alignment: 'left'
          }}>
          Colors are the first thing people notice. We make <u>colors pop</u> and <u>products shine</u>.
        </WideCardElement>
      </SectionTwoElement>
      <SectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: model.assets.images.floating.ux1,
          middle: model.assets.images.floating.ux2,
          bottom: model.assets.images.floating.ux3,
          alt: ' A wireframe mockup of a web application.'
        }} />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'ux_design',
            title: 'UX Design',
            alignment: 'left'
          }} >
          Digital products <u>fail without good User Experience</u>.
          Through rigorous revisions we deliver <u>products that work</u>.
        </WideCardElement>
      </SectionTwoElement>
      <SectionTwoElement alignment='left'>
        <FloatingImageElement data={{
          top: model.assets.images.floating.branding1,
          bottom: model.assets.images.floating.branding2,
          alt: '2Front logomark'
        }} />
        <WideCardElement
          className='text-card-element'
          data={{
            id: 'branding',
            title: 'Branding',
            alignment: 'left'
          }}>
          <u>More than just a logo</u>, we build brands that <u>people can relate to</u> and grow to love.
        </WideCardElement>
      </SectionTwoElement>
      <CtaSection data={{
        title: 'Need our design expertise?',
        accent: {
          text: 'Get started',
          link: model.paths.getStarted
        },
        secondary: {
          text: 'Or read about our other stuff!',
          link: model.paths.work
        }
      }} />
    </PageLayout>
  );
}

DesignView.defaultProps = {
}

const mapState = (state: RootState) => {
  return ({});
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(DesignView);
